import '@css/site.scss';

function ready(fn) {
    if (document.readyState != 'loading') {
        fn();
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}
ready(go);

function go() {

    // global vars for window width, body element, scroll effects, etc
    var w = window,
        d = document,
        e = d.documentElement,
        g = d.getElementsByTagName('body')[0],
        x = w.innerWidth || e.clientWidth || g.clientWidth,
        y = w.innerHeight || e.clientHeight || g.clientHeight;

    if (typeof document.getElementById('cookie-policy-accept') !== 'undefined' && document.getElementById('cookie-policy-accept') !== null) {
        document.getElementById('cookie-policy-accept').addEventListener('click', function(e) {
            e.preventDefault();
            document.cookie = "acceptCookiePolicy=true;expires=Fri, 31 Dec 2030 23:59:59 GMT";
            document.getElementById('cookie-policy-modal').style.display = 'none';
        });
    }

    /*
     ** Sets up hamburger menu icon/toggle ======================================
     */
    var menuToggle = document.getElementById('menu-toggle');
    menuToggle.addEventListener('click', function(e) {
        e.preventDefault();
        if (this.classList.contains('open')) {
            document.getElementById('nav-primary').classList.remove('open');
            document.getElementById('masthead').classList.remove('open');
            this.setAttribute('aria-expanded', 'false');
            this.classList.remove('open');
            document.body.classList.remove('nav-open');
        } else {
            document.getElementById('nav-primary').classList.add('open');
            document.getElementById('masthead').classList.add('open');
            this.setAttribute('aria-expanded', 'true');
            this.classList.add('open');
            document.body.classList.add('nav-open');
        }
    });

    function collapseSubNavs(thisX) {
        var subnavs = document.querySelectorAll('.has-children');
        Array.prototype.forEach.call(subnavs, function(el, i) {
            el.setAttribute('data-height', el.scrollHeight);
            el.classList.add('collapsed');
        });
    }

    function mobileNav(thisX) {
        var subnavExpand = document.querySelectorAll('.nav-primary > ul > li.has-children > a');
        collapseSubNavs(thisX);
        Array.prototype.forEach.call(subnavExpand, function(el, i) {
            el.addEventListener('click', function(e) {
                console.log(thisX);
                if (thisX <= 1023) {
                    console.log('click');
                    e.preventDefault();
                    // subnav = el.querySelectorAll('.subnav')[0];
                    collapseSubNavs(thisX);
                    el.parentNode.classList.remove('collapsed');
                }
            });
        });
    }
    mobileNav(x);

    var fadeInBoxes = document.querySelectorAll('.fade-in');
    var listFadeInBoxes = document.querySelectorAll('.list-fade-in');

    var globalId;

    function scrollStuff() {
        var body = g.getBoundingClientRect();
        // console.log(body.top);

        Array.prototype.forEach.call(fadeInBoxes, function(el, i) {
            var rect = el.getBoundingClientRect();
            if (rect.top < (y - 100))
                if (!el.classList.contains('visible'))
                    el.classList.add('visible');
        });
        Array.prototype.forEach.call(listFadeInBoxes, function(el, i) {
            var rect = el.getBoundingClientRect();
            if (rect.top < (y - 100))
                if (!el.classList.contains('visible'))
                    el.classList.add('visible');
        });
        if (body.top < -110)
            g.classList.add('scrolling');
        else
            g.classList.remove('scrolling');
        globalId = requestAnimationFrame(scrollStuff);
    }

    globalId = requestAnimationFrame(scrollStuff);

    if (typeof document.getElementById('our-approach-buttons') !== 'undefined' && document.getElementById('our-approach-buttons') !== null) {
        var tabButtons = document.querySelectorAll('#our-approach-buttons a');
        Array.prototype.forEach.call(tabButtons, function(el, i) {
            el.addEventListener('click', function(e) {
                e.preventDefault();

                Array.prototype.forEach.call(tabButtons, function(link, i) {
                    link.classList.remove('active');
                });
                el.classList.add('active');
                module = document.getElementById('ourApproach');
                switch (el.id) {
                    case 'tab1':
                        module.classList.remove('tab1');
                        module.classList.remove('tab2');
                        module.classList.remove('tab3');
                        module.classList.add('tab1');
                        break;
                    case 'tab2':
                        module.classList.remove('tab1');
                        module.classList.remove('tab2');
                        module.classList.remove('tab3');
                        module.classList.add('tab2');
                        break;
                    case 'tab3':
                        module.classList.remove('tab1');
                        module.classList.remove('tab2');
                        module.classList.remove('tab3');
                        module.classList.add('tab3');
                        break;
                }
            })
        });
    }

    if (typeof document.getElementById('leadership-nav') !== 'undefined' && document.getElementById('leadership-nav') !== null) {
        var tabLinks = document.querySelectorAll('#leadership-nav a');
        var panels = document.querySelectorAll('.people-wrapper');

        function setActiveTabFromHash() {
            var hash = window.location.hash.replace('#', ''); // Remove the '#' from the hash
            var activeFound = false;

            Array.prototype.forEach.call(tabLinks, function(el, i) {
                el.classList.remove('active');
                panels[i].classList.remove('active');

                // Set active class if hash matches
                if (el.getAttribute('href').replace('#', '') === hash) {
                    el.classList.add('active');
                    panels[i].classList.add('active');
                    activeFound = true;
                }
            });

            // Fallback to default tab if no hash match is found
            if (!activeFound && tabLinks.length > 0 && panels.length > 0) {
                tabLinks[0].classList.add('active');
                panels[0].classList.add('active');
            }

            // Scroll to top of section
            if (hash) {
                var panel = document.querySelector('#leadership-nav');
                if (panel) {
                    window.scrollTo({
                        top: panel.offsetTop - 120,
                        behavior: 'smooth'
                    })
                }
            }
        }

        window.addEventListener('hashchange', setActiveTabFromHash, false);

        Array.prototype.forEach.call(tabLinks, function(el, i) {
            el.addEventListener('click', function(e) {
                e.preventDefault();

                // Update URL hash
                window.location.hash = el.getAttribute('href');
            });
        });

        // Call on initial load
        setActiveTabFromHash();
    }

    if (typeof document.getElementById('bubble-popup-1') !== 'undefined' && document.getElementById('bubble-popup-1') !== null) {
        var bubbles = document.querySelectorAll('.bubble');
        var bubbleLinks = document.querySelectorAll('.button-popup');
        Array.prototype.forEach.call(bubbles, function(el, i) {
            var span = el.querySelectorAll('span:nth-of-type(3)')[0];
            span.style.height = 0;
        });
        Array.prototype.forEach.call(bubbles, function(el, i) {
            el.addEventListener('click', function(e) {
                e.preventDefault();
                index = Array.prototype.indexOf.call(bubbles, el);
                var bubble = el;
                var spans = bubble.querySelectorAll('span:nth-of-type(3)');
                var bubbleSpan = spans[0];
                if (el.classList.contains('active')) {
                    bubbleSpan.style.transition = '.2s opacity, .2s visibility, .4s height';
                    bubbleSpan.style.height = 0;
                    bubble.classList.remove('active');
                    bubbleLinks.item(index).classList.remove('active');
                } else {
                    Array.prototype.forEach.call(bubbleLinks, function(link, i) {
                        link.classList.remove('active');
                    });
                    Array.prototype.forEach.call(bubbles, function(div, i) {
                        div.classList.remove('active');
                        var span = div.querySelectorAll('span:nth-of-type(3)')[0];
                        span.style.transition = '.2s opacity, .2s visibility, .4s height';
                        span.style.height = 0;
                    });
                    bubbleSpan.style.transition = '.4s opacity, .4s visibility, .2s height';
                    bubbleSpan.style.height = bubbleSpan.scrollHeight + 16 + 8 + 'px';
                    el.classList.add('active');
                    bubbleLinks.item(index).classList.add('active');
                }
            });
        });
    }

    /*
     ** START window resize functions ===========================================
     */
    var resizeId;
    window.addEventListener('resize', function() {
        clearTimeout(resizeId);
        resizeId = setTimeout(doneResizing, 5);
    });

    function doneResizing() {
        var resizeX = w.innerWidth || e.clientWidth || g.clientWidth,
            resizeY = w.innerHeight || e.clientHeight || g.clientHeight;
        console.log(resizeX);
        mobileNav(resizeX);
    }
    /*
     ** END window resize functions =============================================
     */

    /*
     ** START external link intercept ============================================
     */
    const links = document.querySelectorAll('a[href]');
    console.log(links);
    links.forEach(function(link, index) {
        let href = link.getAttribute('href');
        if (
            href.indexOf('http') == 0 &&
            href.indexOf(window.siteDomain) == -1
        ) {
            link.addEventListener('click', function(e) {
                e.preventDefault();
                // 1. insert link into modal
                document
                    .getElementById('follow-link')
                    .setAttribute(
                        'href',
                        link.getAttribute('href')
                    );
                // 2. show modal
                document.querySelector('body').classList.add('link-intercept');
            });
        }
    });
    document.getElementById('close-link-modal').addEventListener('click', function(e) {
        document.querySelector('body').classList.remove('link-intercept');
    })
    /*
     ** END external link intercept ==============================================
     */

    /*
     ** START Publications filters ===============================================
     */

    var yearFilters = document.querySelectorAll('#dropdown button');

    function filterPubs(button) {
        var year = button.getAttribute('data-year');
        var articlesAll = document.querySelectorAll('.publications-year');

        if (year == 'all') {
            var activeYear = 'all';
            Array.prototype.forEach.call(articlesAll, function(el, i) {
                el.classList.add('active-year');
                el.classList.remove('filtered-year');
                el.style.height = el.getAttribute('data-height') + 'px';
            });
        } else {
            if (activeYear == year) {
                // do nothing
            } else {
                activeYear = year;
                Array.prototype.forEach.call(articlesAll, function(el, i) {
                    el.classList.remove('active-year');
                    el.classList.remove('filtered-year');
                    el.style.height = 0 + 'px';
                });
                var activeArticles = document.getElementById('publications-year-' + year);
                activeArticles.classList.add('active-year');
                activeArticles.classList.add('filtered-year');
                activeArticles.style.height = activeArticles.getAttribute('data-height') + 'px';
            }
        }
    }

    if (yearFilters.length > 0) {
        var activeYear = 'all';
        dropdown = document.getElementById('dropdown');
        var dropdownButton = dropdown.querySelectorAll('button');
        dropdown.setAttribute('data-height', dropdown.scrollHeight);
        dropdownButton.forEach(function(button, index) {
            button.addEventListener('click', function(e) {
                var firstButton = dropdown.querySelectorAll('li:first-child')[0];
                if (dropdown.classList.contains('open')) {
                    firstButton.style.marginTop = dropdown.getAttribute('data-restore');
                    firstButton.style.marginTop = '-45px';
                    firstButton.style.marginTop = (-1 * index * 45) + 'px';
                    dropdown.classList.remove('open');
                    dropdown.style.height = '36px';
                } else {
                    var topMargin = firstButton.style.marginTop;
                    dropdown.setAttribute('data-restore', topMargin);
                    dropdown.querySelectorAll('li:first-child')[0].style.marginTop = '0px';
                    dropdown.classList.add('open');
                    dropdown.style.height = dropdown.getAttribute('data-height') + 'px';
                }
            });
        });
        Array.prototype.forEach.call(yearFilters, function(el, i) {
            el.addEventListener('click', function(e) {
                filterPubs(el);
            });
        });
        var articlesAll = document.querySelectorAll('.publications-year');
        Array.prototype.forEach.call(articlesAll, function(el, i) {
            el.setAttribute('data-height', el.scrollHeight);
            el.style.height = el.getAttribute('data-height') + 'px';
        });
    }
}
